import _ from 'lodash';
import i18n from '../components/i18n';

export const authFromResponse = (data, headers) => {
  const { authorization } = headers;
  return { ...data, authorization };
};

export const interpolateMessage = (message, contact, user, campaign = {}) => {
  let text = message;
  if (!text) {
    return text;
  }

  if (user) {
    text = text.replace(
      '{{user.first_name}}',
      _.capitalize(user.first_name) || campaign.fallback_user_first_name,
    );

    text = text.replace('{{user.last_name}}', _.capitalize(user.last_name));
    const fullName = _.trim(
      `${_.capitalize(user.first_name) || campaign.fallback_user_first_name} ${_.capitalize(
        user.last_name,
      )}`,
    );
    text = text.replace('{{user.fullname}}', fullName);
    text = text.replace('{{user.full_name}}', fullName);
    text = text.replace('{{user.email}}', user.email);
    text = text.replace('{{user.phone}}', user.phone);
  }

  if (contact) {
    text = text.replace(
      '{{contact.first_name}}',
      _.capitalize(contact.first_name) || campaign.fallback_contact_first_name,
    );

    text = text.replace('{{contact.last_name}}', _.capitalize(contact.last_name));
    const fullName = _.trim(
      `${_.capitalize(contact.first_name) || campaign.fallback_contact_first_name} ${_.capitalize(
        contact.last_name,
      )}`,
    );
    text = text.replace('{{contact.fullname}}', fullName);
    text = text.replace('{{contact.full_name}}', fullName);
    text = text.replace('{{contact.email}}', contact.email);
    text = text.replace('{{contact.phone}}', contact.phone);

    text = text.replace(
      '{{first_name}}',
      _.capitalize(contact.first_name) || campaign.fallback_contact_first_name,
    );

    text = text.replace('{{last_name}}', _.capitalize(contact.last_name));
    text = text.replace('{{fullname}}', fullName);
    text = text.replace('{{full_name}}', fullName);
    text = text.replace('{{email}}', contact.email);
    text = text.replace('{{phone}}', contact.phone);

    if (contact.customizations) {
      contact.customizations.forEach(customization => {
        if (
          customization?.['custom_field'] != null &&
          text.match(`{{${customization?.['custom_field']?.['slug']}}}`)
        ) {
          text = text.replace(
            `{{${customization['custom_field']['slug']}}}`,
            customization['value'] || customization['custom_field']['fallback_value'],
          );
        }
      });
    }

    if (contact.custom_fields) {
      contact.custom_fields.forEach(custom_field => {
        if (custom_field != null && text.match(`{{${custom_field?.['slug']}}}`)) {
          text = text.replace(
            `{{${custom_field['slug']}}}`,
            custom_field['value'] || custom_field['fallback_value'],
          );
        }
      });
    }
  }

  const safeVariables = [
    'ga_absentee_ballot_link',
    'ga_registration_link',
    'outvote_volunteer_link',
  ];
  const safeRegexString = safeVariables.map(v => `{{${v}}}`).join('|');

  const recognizedRegex = `(?!${safeRegexString})`;
  const unrecognizedRegex = '{{[\\w.]*}}';

  let unrecognized_variables = new RegExp(`${recognizedRegex}${unrecognizedRegex}`, 'g');
  text = text.replace(unrecognized_variables, '');

  return text;
};

export const getButtonText = (type, completed) => {
  if (completed) {
    return (
      {
        donate_activities: i18n.t('button.donate_again'),
        news_activities: i18n.t('button.read_more'),
        share_activities: i18n.t('button.share_again'),
        text_a_friend_activities: i18n.t('button.email_again'),
        text_a_list_activities: i18n.t('button.text_again'),
        web_activites: i18n.t('button.view_again'),
      }[type] || i18n.t('button.start_again')
    );
  }

  return (
    {
      donate_activities: i18n.t('button.donate'),
      news_activities: i18n.t('button.read_more'),
      share_activities: i18n.t('button.share'),
      text_a_friend_activities: i18n.t('button.start'),
      text_a_list_activities: i18n.t('button.start'),
      web_activites: i18n.t('button.go'),
    }[type] || i18n.t('button.start')
  );
};

/**
 * DK - 26/02/2020 - This might no longer be needed for this specific purpose,
 * but leaving it here in case we decide this is how we want to translate
 * hardcoded 'button_text' attribute of an activity.
 */
export const mobileButtonTextToWeb = text => {
  switch (text) {
    // comes hardcoded from database as 'button_text' attribute of an activity
    // case 'Start Texting':
    //   return i18n.t('button.start_emailing');

    default:
      return text;
  }
};

export const verbByType = type => {
  return (
    {
      donate_activities: i18n.t('verbs.donated_to'),
      news_activities: i18n.t('verbs.shared_news_for'),
      share_activities: i18n.t('verbs.shared_for'),
      text_a_friend_activities: i18n.t('verbs.emailed_friends_for'),
      text_a_list_activities: i18n.t('verbs.texted_for'),
    }[type] || i18n.t('verbs.helped')
  );
};

export const sortByDisplayOrderFn = (a, b) => {
  if (!a || !b) return 1;
  if (a.display_order === null && b.display_order === null) return -1;
  if (a.display_order === null && b.display_order !== null) return 1;
  if (a.display_order !== null && b.display_order === null) return -1;
  return a.display_order > b.display_order ? 1 : -1;
};

// In this function, we replace all English onboarding fields with localized ones if they are not equal to null.
export const getLocalizedOnboardingData = campaign => {
  if (campaign.localization) {
    for (let i in campaign.localization) {
      if (campaign.localization[i]) {
        campaign[i] = campaign.localization[i];
      }
    }
  }

  return campaign;
};

// Determine if a color is dark or light, we use this to determine the text color of the submit button
// https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color/596241#596241
export const isDark = color => {
  if (!color) return false;
  const c = color.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  return r * 0.299 + g * 0.587 + b * 0.114 < 186;
};

export const statFormatted = value => {
  if (!value || value < 0) return 0;
  if (value) return Math.round(Number(value)).toLocaleString();
};

export const isAuthFinished = user => {
  const auth = user || {};
  const {
    first_name: first_name_auth,
    last_name: last_name_auth,
    phone: phone_auth,
    last_campaign_id: campaign_id_auth,
    supplied_zip_code: zip_auth,
  } = auth;

  const isAuthFinished = !!(
    first_name_auth &&
    last_name_auth &&
    phone_auth &&
    campaign_id_auth &&
    zip_auth
  );

  return isAuthFinished;
};

export const isShowCampaignOnboarding = campaign => {
  const onboardingCampaign = campaign || {};

  const { instructions_text: description, instructions_video_url: url } = onboardingCampaign;

  const isShowCampaignOnboarding = !!(description || url);

  return isShowCampaignOnboarding;
};
