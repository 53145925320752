export const formatPhoneNumber = (phoneNumber, withCountryCode = false) => {
  if (!phoneNumber) return '';

  // Remove all non-digit characters
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');

  // Check if the number starts with '1' and has 11 digits
  const hasCountryCode = cleaned.length === 11 && cleaned.startsWith('1');

  // Remove the leading '1' if it exists
  const normalized = hasCountryCode ? cleaned.slice(1) : cleaned;

  // Match the normalized number to the pattern
  const match = normalized.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    // add \u00A0 to prevent line breaks
    const formattedNumber = `(${match[1]})\u00A0${match[2]}-${match[3]}`;
    return withCountryCode ? `+1\u00A0${formattedNumber}` : formattedNumber;
  }

  return phoneNumber;
};
